import { useSelector } from 'react-redux';
import { styled } from '@stitches/react';
import { RootState } from 'src/ducks/@store';
import ErrorModal from 'src/modals/ErrorModal';
import SignCompleteModal from 'src/modals/SignCompleteModal';

const ModalProvider = () => {
  const { open, modalType } = useSelector((state: RootState) => state.modal);

  if (!open) {
    return null;
  }

  return (
    <BackdropLayout>
      {modalType === 'SIGN_COMPLETE' && <SignCompleteModal />}
      {modalType === 'ERROR' && <ErrorModal />}
    </BackdropLayout>
  );
};

const BackdropLayout = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '1000',
  width: '-webkit-fill-available',
  height: '-webkit-fill-available',
  background: 'rgba(0, 0, 0, 0.5)',
});

export default ModalProvider;
