import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import React from 'react';
import ModalProvider from 'src/providers/ModalProvider';
import RouterProvider from 'src/providers/RouterProvider';
import globalStyles from 'src/globalStyles';
import { store } from 'src/ducks/@store';
import ToastProvider from './providers/ToastProvider';

const App = () => {
  React.useEffect(() => {
    globalStyles();
  }, []);

  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <ToastProvider />
        <ModalProvider />
        <RouterProvider />
      </ReduxProvider>
    </BrowserRouter>
  );
};

export default App;
