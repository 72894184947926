import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';
import { styled } from 'src/assets/stitches.config';
import { blinkKeyframes } from 'src/styles/animation/Blink';

type Props = {
  currentUserCount: number;
};

const LandingPageRecruitHeaderSection: React.FC<Props> = ({
  currentUserCount,
}) => {
  const [tooltipShown, setTooltipShown] = React.useState(false);
  const clearId = React.useRef<NodeJS.Timeout | null>(null);

  const handleClick = () => {
    if (tooltipShown) return;

    setTooltipShown(true);
    clearId.current = setTimeout(() => {
      setTooltipShown(false);
    }, 3000);
  };

  React.useEffect(() => {
    return () => {
      if (clearId.current) {
        clearTimeout(clearId.current);
      }
    };
  }, []);

  return (
    <Base>
      <UserRecruitTitleContainer>
        <UserRecruitTitle>미래일기 소유자</UserRecruitTitle>
        <CurrentUserCount>
          {' '}
          <b>{currentUserCount}</b> / 99
        </CurrentUserCount>
      </UserRecruitTitleContainer>
      <CommunityGuide>
        <b>멋진 멤버</b>들과 안전하고 밀도있게 성장하고 있어요! ⤴︎
      </CommunityGuide>
    </Base>
  );
};

export default LandingPageRecruitHeaderSection;

const Base = styled('div', {
  margin: '30px 0 0 30px',
});

const UserRecruitTitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'end',
});

const UserRecruitTitle = styled('div', {
  fontSize: '24px',
  fontWeight: 700,
  color: '$gray900',
});

const CurrentUserCount = styled('div', {
  margin: '0 0 0 8px',
  fontWeight: 700,
  whiteSpace: 'pre',

  '& b': {
    animation: `${blinkKeyframes} 3s linear infinite`,
  },
});

const CommunityGuide = styled('div', {
  margin: '10px 0 0 0',
  fontSize: '12px',
  color: '$gray800',

  '& b': {
    fontWeight: 700,
  },
});
