import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@stitches/react';
import { Text } from 'src/ui-kit';
import { RouteKeys } from 'src/config/routes';
import { closeModal } from 'src/ducks/reducers/modal';
import { RootState } from 'src/ducks/@store';

const ErrorModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { payload } = useSelector((state: RootState) => state.modal);

  const handleSubmit = () => {
    navigate(RouteKeys.ROOT);
    dispatch(closeModal());
  };

  return (
    <Layout>
      <Logo color="white" weight="extrabold" size="body2">
        gleefy
      </Logo>
      <GudieText color="white">{payload}</GudieText>
      <SubmitButton isActive={true} onClick={handleSubmit}>
        확인
      </SubmitButton>
    </Layout>
  );
};

const Layout = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Logo = styled(Text, {
  margin: '0 0 0.5rem 0',
});

const GudieText = styled('div', {
  margin: '0 0 1.5rem 0',
  maxWidth: 'min(80%, 300px)',
  lineHeight: '1.5',
  whiteSpace: 'break-spaces',
  textAlign: 'center',
  fontSize: '1rem',
  color: '#FFF',
});

const SubmitButton = styled('div', {
  background: 'white',
  width: '80%',
  padding: '1rem',
  borderRadius: '8px',
  textAlign: 'center',
  fontWeight: 'bold',

  variants: {
    isActive: {
      true: {},
      false: {
        color: 'grey',
      },
    },
  },
});

export default ErrorModal;
