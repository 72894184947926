import { styled } from 'src/assets/stitches.config';

type Props = {
  title?: string;
  children: React.ReactNode;
};

const PageLayout = ({ title, children, ...props }: Props) => {
  return (
    <Base {...props}>
      <Header />
      {title && <TitleContainer>{title}</TitleContainer>}
      <Content>{children}</Content>
      <Bottom />
    </Base>
  );
};

export default PageLayout;

const Base = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: 'calc(100% - 2px)',
  background: '$background',
  overflow: 'hidden',
});

const Border = styled('div', {
  margin: '0 2rem',
  height: '1px',
  background: '$gray500',
  opacity: 0.9,
});

const Header = styled(Border, {
  height: '1.5px',
});

const TitleContainer = styled('div', {
  padding: '2rem',
  fontSize: '2rem',
  fontFamily: '$serif',
});

const Bottom = styled(Border, {
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: 'calc(100% - 4rem)',
});

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  color: '$gray900',
});
