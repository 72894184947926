import { Navigate } from 'react-router-dom';
import FutureLetterPage from 'src/pages/FutureLetterPage';
import FutureNotePage from 'src/pages/FutureNotePage';
import NotePage from 'src/pages/NotePage';
import LandingPage from 'src/pages/pageLanding/LandingPage';
import SignPage from 'src/pages/pageSign/SignPage';

export const RouteKeys = {
  ROOT: '/',
  ALL: '*',
  SIGN: '/sign',
  NOTE: '/note/:nid',
  FUTURE_LETTER: '/future-letter/:fid',
  FUTURE_NOTE: '/future-note/:fid',
};

export const routes = {
  [RouteKeys.SIGN]: {
    path: RouteKeys.SIGN,
    render: () => <SignPage />,
  },
  [RouteKeys.ROOT]: {
    path: RouteKeys.ROOT,
    render: () => <LandingPage />,
  },
  [RouteKeys.ALL]: {
    path: RouteKeys.ALL,
    render: () => <Navigate to="/" replace={true} />,
  },
  [RouteKeys.NOTE]: {
    path: RouteKeys.NOTE,
    render: () => <NotePage />,
  },
  [RouteKeys.FUTURE_NOTE]: {
    path: RouteKeys.FUTURE_NOTE,
    render: () => <FutureNotePage />,
  },
  [RouteKeys.FUTURE_LETTER]: {
    path: RouteKeys.FUTURE_LETTER,
    render: () => <FutureLetterPage />,
  },
} as Record<
  keyof typeof RouteKeys,
  {
    path: string;
    render: () => React.ReactElement;
  }
>;
