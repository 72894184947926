import { Routes, Route } from 'react-router-dom';
import { routes } from 'src/config/routes';

const RouterProvider = () => (
  <Routes>
    {Object.values(routes).map((route) => (
      <Route key={route.path} path={route.path} element={route.render()} />
    ))}
  </Routes>
);

export default RouterProvider;
