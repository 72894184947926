import React from 'react';
import { styled } from '@stitches/react';
import { axios } from 'src/utils/axios';
import LandingPageRecruitHeaderSection from './LandingPageRecruitHeaderSection';
import LandingPageRecruitFooterSection from './LandingPageRecruitFooterSection';
import LandingPageAccordionSection from './LandingPageAccordionSection';

const LandingPage: React.FC = () => {
  const [totalmember, setTotalmember] = React.useState(0);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const res = await axios.get('/statistics');
        setTotalmember(res.data.totalMember);
      } catch {
        return;
      }
    };

    fetch();
  }, []);

  return (
    <Base>
      <Content>
        <LandingPageRecruitHeaderSection currentUserCount={totalmember} />
        <LandingPageAccordionSection />
        <LandingPageRecruitFooterSection />
      </Content>
    </Base>
  );
};

export default LandingPage;

const Base = styled('div', {
  position: 'relative',
  background: '$background',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  overflow: 'hidden',
  color: '$gray900',
});

const Content = styled('div', {
  position: 'relative',
  width: '100%',
  maxWidth: '540px',
});
