import { styled } from 'src/assets/stitches.config';

const SignPageHeader = () => {
  return (
    <TitleContainer>
      <Title>{'미래에서 보내오는\n특별한 메일레터'}</Title>
      <Description>
        {
          ' 우리는 모두 고유한 존재이고, 다른 사람이 이해하기 어려운 각자만의 심연을 가지고 있을 거에요. 그런 나에게 가장 큰 위로, 지혜, 영감, 응원을 줄 수 있는 존재는 미래의 내가 아닐까? 싶었어요.\n\n 동시에 삶이란 다정하고 똑똑한 사람들과 함께 연결될 때 만들어지는 가치가 크다고 믿기에, 미래의 나, 그리고 다른 사람들과 연결되는 커뮤니티 서비스로 글피를 만들게 되었어요!\n\n 일주일에 1회 이상 일기를 쓰고, 일기를 쓴 다음날 미래의 내가 쓴 일기를 받아보는 것 외에 할 일은 없답니다. 현재 서비스는 무료이고, 앞으로도 핵심 기능인 <미래의 내가 쓴 일기 읽기>는 무료로 제공될 예정이에요.\n\n 더 나은 삶을 살아갈 수 있도록 돕는, 세상에 없었던 실험적인 콘텐츠들을 추가적으로 개발해 유료로 제공할 계획이에요. 자신을 탐구하고 삶을 디자인하는 작업에 관심이 많다면 분명 매력적인 기회가 될 거에요.\n\n 현재 99명의 초기 멤버를 모시고 있어요. 아래 신청서를 작성해주시면 연락드릴게요. 안전하고 다정한 커뮤니티를 만들기 위해, 제출해주신 정보만으로 결이 맞다고 판단하기 어려울 경우 따로 연락을 드리지 않고 있는 점 참고해주세요. 고맙습니다.'
        }
      </Description>
    </TitleContainer>
  );
};

export default SignPageHeader;

const TitleContainer = styled('div', {
  maxWidth: '$maxWidth',
  width: '100%',
  lineHeight: 1.2,
  fontWeight: 900,
  letterSpacing: '-1px',
});

const Title = styled('div', {
  fontSize: '1.875rem',
  color: '$gray900',
  textAlign: 'center',
  whiteSpace: 'pre',
});

const Description = styled('div', {
  margin: '3rem 0 1.5rem',
  fontSize: '16px',
  whiteSpace: 'break-spaces',
  fontWeight: 400,
  color: '$gray800',
  width: '100%',
  lineHeight: 1.5,
});
