import React from 'react';
import { set } from 'src/utils/storage';

type FormProps<T> = {
  onSubmit: (form: T) => void;
  formId: string;
  initialValues: T;
  initialFocus?: keyof T;
  schema: Record<keyof T, (value: T[keyof T]) => boolean>;
  children: (props: {
    form: T;
    errors: Record<keyof T, boolean>;
    createHandleChange: (
      key: keyof T
    ) => (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    createHandleCheckboxChange: (
      key: keyof T
    ) => (event: React.MouseEvent<HTMLDivElement>) => void;
    handleSubmit: (
      event:
        | React.MouseEvent<HTMLDivElement, MouseEvent>
        | React.FormEvent<HTMLFormElement>
    ) => void;
  }) => React.ReactElement;
};

// eslint-disable-next-line
const Form = <T extends Record<string, any>>({
  formId,
  initialValues,
  initialFocus,
  children,
  schema,
  onSubmit,
}: FormProps<T>) => {
  const [form, setForm] = React.useState<T>(initialValues);
  const [errors, setErrors] = React.useState<Record<keyof T, boolean>>(
    Object.keys(initialValues).reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {} as Record<keyof T, boolean>
    )
  );

  React.useEffect(() => {
    if (initialFocus) {
      const element = document.querySelector(
        `#${initialFocus as string}`
      ) as HTMLInputElement;
      element.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    set(formId, form, 1000 * 60 * 60 * 6);
  }, [form, formId]);

  const createHandleChange =
    (key: keyof T) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.preventDefault();
      setForm({ ...form, [key]: event.target.value });
      if (errors[key]) {
        setErrors({
          ...errors,
          [key]: !schema[key](event.target.value as T[keyof T]),
        });
      }
    };

  const createHandleCheckboxChange =
    (key: keyof T) => (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      setForm({ ...form, [key]: !form[key] });

      if (errors[key]) {
        setErrors({
          ...errors,
          [key]: !schema[key](!form[key] as T[keyof T]),
        });
      }
    };

  const handleSubmit = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const newErrors = Object.keys(schema).reduce(
      (acc, key) => ({ ...acc, [key]: !schema[key](form[key]) }),
      {} as Record<keyof T, boolean>
    );

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      onSubmit(form);
    } else {
      const firstErrorKey = Object.keys(newErrors).find(
        (key) => newErrors[key]
      );
      if (firstErrorKey) {
        const element = document.querySelector(
          `#${firstErrorKey as string}`
        ) as HTMLElement;
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  };

  return children({
    form,
    errors,
    createHandleChange,
    createHandleCheckboxChange,
    handleSubmit,
  });
};

export default Form;
