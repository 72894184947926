import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { styled } from '@stitches/react';
import { Text } from 'src/ui-kit';
import { RouteKeys } from 'src/config/routes';
import { closeModal } from 'src/ducks/reducers/modal';

const SignCompleteModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    navigate(RouteKeys.ROOT);
    dispatch(closeModal());
  };

  return (
    <Layout>
      <Logo color="white" weight="extrabold" size="body2">
        gleefy
      </Logo>
      <GudieText color="white" size="caption">
        신청이 <Bold>완료</Bold>되었어요.
      </GudieText>
      <SubmitButton isActive={true} onClick={handleSubmit}>
        확인
      </SubmitButton>
    </Layout>
  );
};

const Layout = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Logo = styled(Text, {
  margin: '0 0 0.5rem 0',
});

const Bold = styled('span', {
  fontWeight: 'bold',
});

const GudieText = styled(Text, {
  margin: '0 0 1.5rem 0',
  whiteSpace: 'break-spaces',
  textAlign: 'center',
});

const SubmitButton = styled('div', {
  background: 'white',
  width: '100%',
  padding: '1rem',
  borderRadius: '8px',
  textAlign: 'center',
  fontWeight: 'bold',

  variants: {
    isActive: {
      true: {},
      false: {
        color: 'grey',
      },
    },
  },
});

export default SignCompleteModal;
