import React from 'react';
import { styled } from '@stitches/react';
import TypeWriter from 'typewriter-effect';
import PageLayout from './common/PageLayout';

const FutureLetterPageContainer = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [content, setContent] = React.useState('');
  const [topics, setTopics] = React.useState<string[]>(['미정']);
  const [predictedAt, setPredictedAt] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setContent('내일은 더 행복해질거야. 믿어. 제발.');
    setPredictedAt(new Date(Date.now() + 1000 * 60 * 60 * 24 * 7));
    setTopics(['일상']);
    setIsLoaded(true);

    // axios
    //   .get('/future-note')
    //   .then((res) => {
    //     const futureNote = res.data.note;

    //     if (!futureNote.content) {
    //       throw `미래일기가 없어요.`;
    //     }

    //     setContent(futureNote.content);
    //     setPredictedAt(new Date(futureNote.futureDate));
    //     setTopics(futureNote.topics ?? ['일상']);
    //   })
    //   .catch(() => {
    //     return;
    //   })
    //   .finally(() => {
    //     setIsLoaded(true);
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoaded) {
    return (
      <FutureNotePage
        content={content}
        predicedAt={predictedAt}
        topics={topics}
      />
    );
  }

  return <Base />;
};

const FutureNotePage: React.FC<{
  content: string;
  predicedAt: Date | null;
  topics: string[];
}> = ({ content, predicedAt, topics }) => {
  const formattedDate = React.useMemo(() => {
    if (!predicedAt) return '쓰여지지 않음';

    const year = predicedAt.getFullYear();
    const month = predicedAt.getMonth() + 1;
    const date = predicedAt.getDate();

    return `${year}년 ${month}월 ${date}일`;
  }, [predicedAt]);

  return (
    <PageLayout title="미래편지">
      <Base>
        <Header>
          <DateContainer>
            <HeaderSectionTitle>날짜</HeaderSectionTitle>
            <DateLabel>{formattedDate}</DateLabel>
          </DateContainer>
          <TopicContainer>
            <HeaderSectionTitle>주제</HeaderSectionTitle>
            <TopicKeywordContainer>
              {topics.map((topic, i) => (
                <TopicKeyword key={i}>{topic}</TopicKeyword>
              ))}
            </TopicKeywordContainer>
          </TopicContainer>
        </Header>
        <Text>
          {content && (
            <TypeWriter
              onInit={(typewriter) => {
                if (content) {
                  typewriter.typeString(content).start();
                }
              }}
            ></TypeWriter>
          )}
        </Text>
      </Base>
    </PageLayout>
  );
};

export default FutureLetterPageContainer;

const Base = styled('div', {
  position: 'relative',
  minHeight: '100%',
  background: '$background',
  overflow: 'scroll',
});

const Header = styled('div', {
  display: 'flex',
  padding: '0 1.5rem',
  justifyContent: 'space-between',
});

const HeaderSectionTitle = styled('div', {
  color: '$gray800',
  fontSize: '0.8rem',
  fontFamily: '$sanSerif',
  fontWeight: '600',
});

const DateContainer = styled('div');

const DateLabel = styled('div', {
  margin: '0.75rem 0 0',
  fontSize: '0.8rem',
  fontFamily: '$sanSerif',
  fontWeight: '800',
  color: '$gray800',
});

const TopicContainer = styled('div');

const TopicKeywordContainer = styled('div', {
  margin: '0.75rem 0 0',
  fontSize: '0.8rem',
  fontFamily: '$sanSerif',
  fontWeight: '800',
  color: '$gray800',
});

const TopicKeyword = styled('div', {
  '&:nth-child(n+2)': {
    margin: '0.375rem 0 0',
  },
});

const Text = styled('div', {
  fontFamily: '$sanSerif',
  lineHeight: 1.5,
  fontSize: '1rem',
  color: '$gray800',
  background: 'transparent',
  padding: '2rem 1.5rem 1.5rem',

  '&:focus': {
    outline: 'none',
  },
});
