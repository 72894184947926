import { createStitches } from '@stitches/react';
import * as Stitches from '@stitches/react';

export const { styled, createTheme, config, css, keyframes, theme, globalCss } =
  createStitches({
    theme: {
      colors: {
        background: '#f8f8f8',
        gray900: '#222222',
        gray800: '#393838',
        gray700: '#e7e2e2',
        gray500: '#818080',
      },
      sizes: {
        maxWidth: '400px',
        mainContentHeight: '82vh',
      },
      space: {
        mainContentHeight: '82vh',
      },
      fonts: {
        serif: 'Song Myung, serif',
        sanSerif: 'Nanum Gothic, sans-serif',
      },
    },
    utils: {},
    media: {},
  });

export type StitchesCSS = Stitches.CSS<typeof config>;
