import { Toaster } from 'react-hot-toast';

const ToastProvider = () => {
  return (
    <Toaster
      toastOptions={{
        success: {
          style: {
            fontSize: '0.8rem',
            padding: '0.2rem',
          },
          icon: null,
        },
        error: {
          style: {
            fontSize: '0.8rem',
            padding: '0.2rem',
          },
          icon: null,
        },
      }}
    />
  );
};

export default ToastProvider;
