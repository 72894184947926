import { styled } from 'src/assets/stitches.config';
import { MS, dayToKorean } from 'src/utils/date';
import AnimationAccordion, {
  AccordionItem,
} from 'src/components/pageLanding/AnimationAccordion';

const formatDate = (date: Date) => {
  return `${date.getFullYear()}년 ${
    date.getMonth() + 1
  }월 ${date.getDate()}일(${dayToKorean(date.getDay())})`;
};

const accordionData: AccordionItem[] = [
  {
    title: formatDate(new Date(Date.now() + 2 * MS.WEEK)),
    content:
      '안 들어가던 바지가 들어갔다. 2주 전에도 들어가기는 했는데 정말 들어만 거고, 바지 앞 단추를 잠그기가 힘들었었다. 이제는 된다. 역시 다이어트는 과학이다. 덜 먹어야 하고, 더 운동해야 한다. 몸이 기초대사량을 쉽게 낮추지 않도록 칼로리는 조금씩 줄여야 하고, 탄수화물은 적게 섭취해야 한다. 몇 가지 절대적인 규칙을 알고, 또 반대로 쓸데없는 규칙에 마음 팔지 않으면 살은 반드시 빠진다.',
  },
  {
    title: formatDate(new Date(Date.now() + 3 * MS.MONTH)),
    content:
      '느낌이 이상했다. 동료들이 나를 모르는 척하며 수군거렸다. 그러다가 심각한 표정으로 나를 불렀다. 내가 뭘 잘못했나? 라운지로 같이 나가는데 눈물이 핑 돌았다. 내 생일이었다.',
  },
  {
    title: formatDate(new Date(Date.now() + 18 * MS.MONTH)),
    content:
      '면허를 따고 실제로 운전을 해본 적이 없었다. 하지만 결국 이런 날도 오는구나. 운전석에 앉아있는 내가 낯설다. 운전이 점점 익숙해진다. 길눈이 밝은 것은 아니지만 교통법을 정확하게 알고 나니까 두려움이 많이 줄었다.',
  },
];

const LandingPageAccordionSection: React.FC = () => {
  return (
    <Base>
      <AnimationAccordion items={accordionData} />
    </Base>
  );
};

export default LandingPageAccordionSection;

const Base = styled('div', {
  padding: 'max(80px, 15vh) 30px 0',
});
