import { IoMdSave } from 'react-icons/io';
import React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { styled } from '@stitches/react';
import { getTTLUntilMidnight } from 'src/utils/date';
import { get, set } from 'src/utils/storage';
import { axios } from '../utils/axios';
import PageLayout from './common/PageLayout';

enum Status {
  LOADING,
  LOADED,
  ERROR,
}

const NotePageContainer = () => {
  const { nid } = useParams();
  const [status, setStatus] = React.useState<Status>(Status.LOADING);
  const [content, setContent] = React.useState('');

  React.useEffect(() => {
    const data = get<string>(`note-${nid}`);

    if (data) {
      setContent(data);
    }

    setStatus(Status.LOADED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.LOADED) {
    return <NotePage content={content} />;
  }

  return <Base />;
};

const NotePage: React.FC<{
  content: string;
}> = ({ content }) => {
  const { nid } = useParams();
  const [isAsyncLoading, setAsyncLoading] = React.useState<boolean>(false);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const currentDate =
    new Date().getFullYear() +
    '년 ' +
    (new Date().getMonth() + 1) +
    '월 ' +
    new Date().getDate() +
    '일';

  const handleSaveClick = () => {
    const ENOUGH_LENGTH = 100;

    if (!textareaRef.current || isAsyncLoading) return;

    const content = textareaRef.current.value;
    if (content.length <= ENOUGH_LENGTH) {
      toast.error('100자 이상 작성해주세요.');
      return;
    }

    set(`note-${nid}`, content, getTTLUntilMidnight());
    setAsyncLoading(true);

    axios
      .post('/note', {
        nid,
        content: textareaRef.current.value,
        date: new Date().toISOString(),
      })
      .then(() => {
        toast.success('저장되었어요.');
      })
      .catch(() => {
        toast.error('저장에 실패했어요. 잠시 후 다시 시도해주세요.');
      })
      .finally(() => {
        setAsyncLoading(false);
      });
  };

  React.useEffect(() => {
    if (!textareaRef.current) return;
    textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';

    textareaRef.current.focus();
    const length = textareaRef.current.value.length;
    if (length > 0) {
      textareaRef.current.setSelectionRange(length, length);
    }
  }, []);

  return (
    <PageLayout>
      <Base>
        <Header>
          <DateContainer>
            <DateContainerTitle>날짜</DateContainerTitle>
            <DateLabel>{currentDate}</DateLabel>
          </DateContainer>
          <SaveButton isActive={!isAsyncLoading} onClick={handleSaveClick}>
            <IoMdSave color="#3d3c3c" />
            <SaveButtonText>저장하기</SaveButtonText>
          </SaveButton>
        </Header>
        <Text
          maxLength={2000}
          defaultValue={content}
          ref={textareaRef}
          onKeyUp={() => {
            if (!textareaRef.current) return;
            textareaRef.current.style.height =
              textareaRef.current.scrollHeight + 'px';
          }}
        />
      </Base>
    </PageLayout>
  );
};

export default NotePageContainer;

const Base = styled('div', {
  position: 'relative',
  minHeight: '100%',
  background: '$background',
  overflow: 'scroll',
});

const Header = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '1rem 1.8rem 0',
});

const DateContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const DateContainerTitle = styled('div', {
  color: '$gray800',
  fontSize: '0.8rem',
  fontFamily: '$sanSerif',
  fontWeight: '600',
});

const DateLabel = styled('div', {
  margin: '0 0 0 1rem',
  fontSize: '0.8rem',
  fontFamily: '$sanSerif',
  fontWeight: '800',
  color: '$gray800',
});

const Text = styled('textarea', {
  margin: '2rem 0 0',
  padding: '0 1.5rem',
  fontFamily: '$sanSerif',
  fontWeight: '500',
  fontSize: '1rem',
  lineHeight: 1.5,
  border: 'none',
  width: '100%',
  background: 'transparent',
  overflowY: 'hidden',
  resize: 'none',
  height: 'auto',
  color: '$gray900',

  '&:focus': {
    outline: 'none',
  },
});

const SaveButton = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '0.5rem',
  borderRadius: '1.25rem',
  fontSize: '14px',

  variants: {
    isActive: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0.5,
      },
    },
  },
});

const SaveButtonText = styled('div', {
  margin: '0 0 0 0.25rem',
  fontWeight: 'bold',
  color: '$gray800',
});
