import React from 'react';
import { VscMail } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { styled } from 'src/assets/stitches.config';

const LandingPageRecruitFooterSection: React.FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/sign');
  };

  return (
    <Base>
      <Container onClick={handleClick}>
        <LetterIconWrapper>
          <VscMail size={24} />
        </LetterIconWrapper>
        <TextField>
          진짜 <b>미래의 나</b>와 만나고 싶다면?
        </TextField>
        <ArrowIconWrapper>→</ArrowIconWrapper>
      </Container>
    </Base>
  );
};

export default LandingPageRecruitFooterSection;

const Base = styled('div', {
  position: 'absolute',
  width: '100%',
  bottom: 0,
});

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  margin: '30px',
  padding: '0 16px',
  height: '36px',
  borderRadius: '16px',
  background: '$gray900',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',

  '&:active': {
    boxShadow: 'none',
    background: '$gray800',
  },
});

const TextField = styled('div', {
  flex: 1,
  fontSize: '14px',
  margin: '0 16x',
  color: '$gray700',
  whiteSpace: 'pre',

  '& b': {
    margin: '0 1px 0 0',
    fontWeight: 700,
  },
});

const LetterIconWrapper = styled('div', {
  color: '$gray700',
  margin: '0 16px 0 0',
});

const ArrowIconWrapper = styled('div', {
  color: '$gray700',
  margin: '0 0 0 16px',
});
