import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { styled } from '@stitches/react';

const StyledContainer = styled('div', {
  width: '100%',
  maxWidth: '500px',
  margin: '0 auto',
});

const StyledItem = styled('div', {
  borderTop: '2px solid $gray900',
  marginBottom: '10px',
  borderRadius: '5px',
  overflow: 'hidden',
});

const StyledHeader = styled('button', {
  width: '100%',
  padding: '8px',
  border: 'none',
  background: 'transparent',
  textAlign: 'left',
  cursor: 'pointer',
  fontFamily: 'Song Myung',
  fontSize: '18px',
  fontWeight: 'bold',
});

const StyledContent = styled(animated.div, {
  position: 'relative',
  background: 'white',
  willChange: 'height',
  lineHeight: '1.5',
  fontSize: '14px',
  overflow: 'hidden',
});

const StyledText = styled('p', {
  position: 'absolute',
  left: 15,
  top: 15,
  margin: 0,
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});

export interface AccordionItem {
  title: string;
  content: string;
}

interface AccordionItemProps extends AccordionItem {
  isOpen: boolean;
  onToggle: (index: number) => void;
  index: number;
}

interface AccordionProps {
  items: AccordionItem[];
}

const useAccordion = (
  totalSectionCount = 3,
  interval = 2200
): {
  activeIndex?: number;
  toggle: (index: number) => void;
} => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const lastActiveIndex = React.useRef<number | undefined>(activeIndex);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((current) => {
        const nextIndex =
          ((lastActiveIndex.current ?? 0) + 1) % totalSectionCount;
        return current === -1 ? nextIndex : -1;
      });
    }, interval);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval]);

  useEffect(() => {
    if (activeIndex === -1) return;

    lastActiveIndex.current = activeIndex;
  }, [activeIndex]);

  const toggle = useCallback((index: number) => {
    setActiveIndex((current) => (current === index ? -1 : index));
  }, []);

  return { activeIndex, toggle };
};

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  content,
  isOpen,
  onToggle,
  index,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const springProps = useSpring({
    height: isOpen ? contentRef.current?.scrollHeight || 'auto' : 0,
    padding: isOpen ? 15 : 0,
    config: { tension: 120, friction: 20 },
    delay: isOpen ? 0 : 300,
  });

  return (
    <StyledItem>
      <StyledHeader
        onClick={() => onToggle(index)}
        aria-expanded={isOpen}
        aria-controls={`content-${index}`}
      >
        {title}
      </StyledHeader>
      <StyledContent
        ref={contentRef}
        style={springProps}
        id={`content-${index}`}
        role="region"
        aria-labelledby={`header-${index}`}
      >
        <StyledText>{content}</StyledText>
      </StyledContent>
    </StyledItem>
  );
};

const AnimationAccordion: React.FC<AccordionProps> = ({ items }) => {
  const { activeIndex, toggle } = useAccordion(items.length);

  return (
    <StyledContainer>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          {...item}
          isOpen={index === activeIndex}
          onToggle={toggle}
          index={index}
        />
      ))}
    </StyledContainer>
  );
};

export default AnimationAccordion;
