import { styled } from '@stitches/react';

export const Text = styled('p', {
  display: 'inline',
  lineHeight: '1.5',
  variants: {
    inline: {
      true: {
        display: 'inline',
      },
    },
    preventSelect: {
      true: {
        userSelect: 'none',
      },
    },
    color: {
      black: {
        color: '#000000',
      },
      white: {
        color: '#ffffff',
      },
    },
    size: {
      h1: {
        fontSize: '2.6rem',
      },
      h2: {
        fontSize: '2.2rem',
      },
      h3: {
        fontSize: '1.8rem',
      },
      body1: {
        fontSize: '1.6rem',
      },
      body2: {
        fontSize: '1.4rem',
      },
      caption: {
        fontSize: '1.2rem',
      },
    },
    weight: {
      thin: {
        fontWeight: '100',
      },
      regular: {
        fontWeight: '400',
      },
      medium: {
        fontWeight: '500',
      },
      bold: {
        fontWeight: '700',
      },
      extrabold: {
        fontWeight: '800',
      },
    },
  },
});
