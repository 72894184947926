import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    open: false,
    modalType: '',
    payload: null,
  },
  reducers: {
    openModal: (state, action) => {
      state.open = true;
      state.modalType = action.payload.name;
      state.payload = action.payload.payload;
    },
    closeModal: (state) => {
      state.open = false;
      state.modalType = '';
      state.payload = null;
    },
  },
});

export default modalSlice.reducer;

export const { openModal, closeModal } = modalSlice.actions;
