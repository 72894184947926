import React from 'react';
import { styled } from 'src/assets/stitches.config';

interface TextInputProps {
  name: string;
  placeholder: string;
  label: string;
  tabIndex: number;
  defaultValue?: string;
  errorDescription: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  placeholder,
  label,
  defaultValue,
  tabIndex,
  errorDescription,
  onChange,
}) => {
  return (
    <Base>
      <Header>
        <Label htmlFor={name}>{label}</Label>
        {!!errorDescription && (
          <ErrorDescription>{errorDescription ?? '필수 입력'}</ErrorDescription>
        )}
      </Header>
      <Input
        defaultValue={defaultValue ?? ''}
        onChange={onChange}
        tabIndex={tabIndex}
        type="text"
        id={name}
        name={name}
        placeholder={placeholder}
      />
    </Base>
  );
};

export default TextInput;

const Base = styled('div', {
  margin: '1.5rem 0 0',

  '&:first-of-type': {
    margin: 0,
  },
});

const Header = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
});

const ErrorDescription = styled('span', {
  color: 'red',
  fontSize: '12px',
  marginLeft: '8px',
});

const Label = styled('label', {
  display: 'block',
  fontSize: '16px',
  fontWeight: 'bold',

  '&:first-of-child': {
    margin: 0,
  },
});

const Input = styled('input', {
  background: '$background',
  padding: '8px 12px',
  heigh: '40px',
  borderRadius: '4px',
  border: '1px solid #c4c4c4',
  fontSize: '16px',
  width: '100%',
  boxSizing: 'border-box',
});
