import { globalCss } from '@stitches/react';

const reset = globalCss({
  html: {
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: '100%',
    font: 'inherit',
    verticalAlign: 'baseline',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  '*:active': {
    WebkitTapHighlightColor: 'transparent',
  },
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  },
  body: {
    lineHeight: 1,
    width: '100%',
    height: '100%',
  },
  ol: {
    listStyle: 'none',
  },
  q: {
    quotes: 'none',
    '&::before, &::after': {
      content: '',
    },
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  '*': {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  },
  '#root': {
    width: '100%',
    height: '100%',
  },
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
  li: {
    listStyle: 'none',
  },
});

export default reset;
