export function toKRDate(date: Date) {
  const pad = (n: number) => (n < 10 ? `0${n}` : n);
  const year = pad(date.getFullYear());
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hour = pad(date.getHours());
  const minute = pad(date.getMinutes());
  const second = pad(date.getSeconds());

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function isPast(date: Date) {
  return new Date(date).getTime() < new Date().getTime();
}

export const isDatesEqual = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const isToday = (date: Date) => {
  return isDatesEqual(date, new Date());
};

export const dayToKorean = (day: number) => {
  switch (day) {
    case 0:
      return '일';
    case 1:
      return '월';
    case 2:
      return '화';
    case 3:
      return '수';
    case 4:
      return '목';
    case 5:
      return '금';
    case 6:
      return '토';
    default:
      return '';
  }
};

export const MS = {
  SECOND: 1000,
  MINUTE: 60 * 1000,
  HOUR: 60 * 60 * 1000,
  DAY: 24 * 60 * 60 * 1000,
  WEEK: 7 * 24 * 60 * 60 * 1000,
  MONTH: 30 * 24 * 60 * 60 * 1000,
  YEAR: 365 * 24 * 60 * 60 * 1000,
};

export function getTTLUntilMidnight() {
  const now = new Date().getTime();
  const midnight = new Date(now);
  midnight.setHours(24, 0, 0, 0);

  const msUntilMidnight = midnight.getTime() - now;
  return msUntilMidnight;
}
