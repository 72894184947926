export const Va = {
  String: () => ({
    _required: false,
    _minLength: 0,
    _maxLength: 999999,
    _regex: null as null | RegExp,

    Required: function () {
      this._required = true;
      return this;
    },
    minLength: function (length: number) {
      this._minLength = length;
      return this;
    },
    maxLength: function (length: number) {
      this._maxLength = length;
      return this;
    },
    regex: function (regex: RegExp) {
      this._regex = regex;
      return this;
    },

    // eslint-disable-next-line
    validate: function (value: any) {
      if (typeof value !== 'string') {
        console.log(value);
        return false;
      }
      if (this._required && (!value || value.trim() === '')) {
        return false;
      }
      if (value.length < this._minLength) {
        return false;
      }
      if (value.length > this._maxLength) {
        return false;
      }
      if (this._regex && !this._regex.test(value)) {
        return false;
      }

      return true;
    },
  }),
  Number: () => ({
    _required: false,
    _min: -Infinity,
    _max: Infinity,

    Required: function () {
      this._required = true;
      return this;
    },
    min: function (value: number) {
      this._min = value;
      return this;
    },
    max: function (value: number) {
      this._max = value;
      return this;
    },

    // eslint-disable-next-line
    validate: function (value: any) {
      if (typeof value !== 'number') {
        return false;
      }
      if (this._required && (value === undefined || value === null)) {
        return false;
      }
      if (value < this._min) {
        return false;
      }
      if (value > this._max) {
        return false;
      }

      return true;
    },
  }),
  Boolean: () => ({
    _required: false,
    _true: false,

    Required: function () {
      this._required = true;
      return this;
    },

    True: function () {
      this._true = true;
      return this;
    },

    // eslint-disable-next-line
    validate: function (value: any) {
      console.log(value, typeof value, this._required, this._true);
      if (typeof value !== 'boolean') {
        return false;
      }
      if (this._required && value === undefined) {
        return false;
      }
      if (this._true && !value) {
        return false;
      }

      return true;
    },
  }),
};
