import React from 'react';
import { styled } from '@stitches/react';

const TermsAgreement = ({
  tabIndex,
  text,
  checked,
  onChange,
  isError,
}: {
  tabIndex: number;
  text: string;
  checked: boolean;
  onChange: (e: React.MouseEvent<HTMLDivElement>) => void;
  isError: boolean;
}) => {
  const handleClick = () => {
    onChange({
      preventDefault: () => {
        return;
      },
      target: { checked: !checked },
    } as unknown as React.MouseEvent<HTMLDivElement>);
  };

  return (
    <CheckboxContainer>
      <StyledCheckbox
        onClick={handleClick}
        isError={isError}
        isChecked={checked}
      />
      <TermsText>{text}</TermsText>
    </CheckboxContainer>
  );
};

export default TermsAgreement;

const CheckboxContainer = styled('label', {
  margin: '1rem 0 0',
  display: 'inline-flex',
  alignItems: 'flex-start',
  cursor: 'pointer',
});

const StyledCheckbox = styled('span', {
  display: 'inline-block',
  width: '16px',
  height: '16px',
  borderRadius: '3px',
  margin: '2px 8px 0 0',
  transition: 'all 150ms',

  variants: {
    isError: {
      true: {
        border: '2px solid red',
      },
      false: {
        border: '1px solid #ccc',
      },
    },
    isChecked: {
      true: {
        '&::after': {
          backgroundColor: '$gray900',
        },
      },
      false: {},
    },
  },

  defaultVariants: {
    isError: false,
  },

  '&::after': {
    content: '',
    display: 'block',
    width: '8px',
    height: '8px',
    margin: '3px',
    borderRadius: '2px',
    backgroundColor: '#fff',
    transition: 'all 150ms',
  },
});

const TermsText = styled('span', {
  fontSize: '12px',
  lineHeight: '1.4',
});
