import React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@stitches/react';
import { clear, get } from 'src/utils/storage';
import { Va } from 'src/utils/form';
import { axios } from 'src/utils/axios';
import { openModal } from 'src/ducks/reducers/modal';
import TextInput from 'src/components/common/form/TextInput';
import TextArea from 'src/components/common/form/TextArea';
import TermsAgreement from 'src/components/common/form/termsAgreement';
import NumberInput from 'src/components/common/form/NumberInput';
import Form from 'src/components/common/form/Form';
import SignPageHeader from './SignPageHeader';

type FormType = {
  name: string;
  email: string;
  phone: string;
  question: string;
  topic: string;
  introduce: string;
  aiTermsAgreed: boolean;
  servicePolicyTermsAgreed: boolean;
};

const FORM_STORAGE_KEY = 'GLEEFY::FORM::SIGN';

const SignPage: React.FC = () => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<FormType | null>(
    null
  );

  const handleSubmit = async (form: FormType) => {
    try {
      await axios.post('/waitlister', {
        name: form.name,
        email: form.email,
        phone: form.phone,
        question: form.question,
        topic: form.topic,
        introduce: form.introduce,
      });
      dispatch(openModal({ name: 'SIGN_COMPLETE' }));
      clear(FORM_STORAGE_KEY);
    } catch (err) {
      dispatch(
        openModal({
          name: 'ERROR',
          payload:
            '이미 신청한 경우 다시 신청할 수 없어요. 만약 처음이라면 이후 다시 신청해주세요.',
        })
      );
    }
  };

  React.useEffect(() => {
    const form = get<FormType>(FORM_STORAGE_KEY);

    if (form) {
      setInitialValues(form);
    } else {
      setInitialValues({
        name: '',
        email: '',
        phone: '',
        question: '',
        topic: '',
        introduce: '',
        aiTermsAgreed: false,
        servicePolicyTermsAgreed: false,
      });
    }
  }, []);

  return (
    <Base>
      <SignPageHeader />

      {initialValues && (
        <Form
          formId={FORM_STORAGE_KEY}
          initialValues={initialValues}
          schema={{
            name: (v) =>
              Va.String().Required().minLength(2).maxLength(5).validate(v),
            email: (v) =>
              Va.String()
                .Required()
                .regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
                .validate(v),
            phone: (v) =>
              Va.String()
                .Required()
                .regex(/^\d{3}-\d{3,4}-\d{4}$/)
                .validate(v),
            question: (v) =>
              Va.String().Required().minLength(100).maxLength(1000).validate(v),
            topic: (v) =>
              Va.String().Required().minLength(100).maxLength(1000).validate(v),
            introduce: (v) =>
              Va.String().Required().minLength(100).maxLength(2000).validate(v),
            aiTermsAgreed: (v) => Va.Boolean().Required().True().validate(v),
            servicePolicyTermsAgreed: (v) =>
              Va.Boolean().Required().True().validate(v),
          }}
          onSubmit={handleSubmit}
        >
          {({
            form,
            errors,
            createHandleChange,
            createHandleCheckboxChange,
            handleSubmit,
          }) => (
            <FormContainer>
              <TextInput
                onChange={createHandleChange('name')}
                tabIndex={1}
                name="name"
                placeholder="본명"
                label="이름"
                defaultValue={form.name}
                errorDescription={
                  errors.name ? '2자 이상 필수 입력(성 포함)' : ''
                }
              />
              <TextInput
                onChange={createHandleChange('email')}
                tabIndex={2}
                name="email"
                defaultValue={form.email}
                placeholder="example@gmail.com"
                label="이메일"
                errorDescription={errors.email ? '이메일 형식에 맞게 입력' : ''}
              />
              <NumberInput
                onChange={createHandleChange('phone')}
                tabIndex={3}
                name="phone"
                defaultValue={form.phone}
                placeholder="010-0000-0000"
                label="연락처"
                errorDescription={errors.phone ? '010-1234-5678 형식' : ''}
              />
              <TextArea
                onChange={createHandleChange('question')}
                tabIndex={4}
                name="question"
                placeholder=""
                label="미래의 나에게 궁금한 것들"
                defaultValue={form.question}
                errorDescription={errors.question ? '100자 이상' : ''}
              />
              <TextArea
                onChange={createHandleChange('topic')}
                tabIndex={5}
                name="topic"
                placeholder=""
                label="요즘 내 삶에 필요한 것들"
                defaultValue={form.topic}
                errorDescription={errors.topic ? '100자 이상' : ''}
              />
              <TextArea
                onChange={createHandleChange('introduce')}
                tabIndex={6}
                rows={8}
                name="introduce"
                placeholder="어떤 분인지 알 수 있도록 스스로에 대해 소개해주세요."
                label="자기소개"
                defaultValue={form.introduce}
                errorDescription={errors.introduce ? '100자 이상' : ''}
              />
              <TermsAgreement
                tabIndex={7}
                text="(필수) 사용자가 제출하는 데이터를 수집하고, 인공지능(LLM)을 통해 학습하고,
        분석하고, 서비스에 이용하는 것에 동의합니다. 사용자가 제출하는 데이터를
        개발자 및 서비스 측에서 직접 읽지는 않습니다."
                checked={form.aiTermsAgreed}
                onChange={createHandleCheckboxChange('aiTermsAgreed')}
                isError={errors.aiTermsAgreed}
              />
              <TermsAgreement
                tabIndex={8}
                text="(필수) 일주일에 1회 이상 일기를 작성하지 않을 경우 이용이 제한되며, 개인정보 보호를 위해 사용자가 제출한 데이터가 삭제되는 것에 동의합니다."
                checked={form.servicePolicyTermsAgreed}
                onChange={createHandleCheckboxChange(
                  'servicePolicyTermsAgreed'
                )}
                isError={errors.servicePolicyTermsAgreed}
              />
              <Button tabIndex={9} onClick={handleSubmit}>
                신청하기
              </Button>
            </FormContainer>
          )}
        </Form>
      )}
    </Base>
  );
};

export default SignPage;

const Base = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '$background',
  minHeight: '100vh',
  color: '$gray900',
  padding: '3rem 2rem',
  fontSize: '1.3rem',
  height: '100%',
  overflowY: 'scroll',
});

const FormContainer = styled('div', {
  margin: '2rem 0 0',
  maxWidth: '400px',
  width: '100%',
  color: '$gray900',
  fontFamily: 'Nanum Gothic, sans-serif',
});

const Button = styled('div', {
  width: '100%',
  margin: '2rem 0 0',
  background: '$gray900',
  borderRadius: '24px',
  color: '$gray700',
  fontSize: '14px',
  padding: '1rem',
  textAlign: 'center',
  fontWeight: 700,
});
